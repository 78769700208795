import React from 'react';
import logo from './logo.svg';
import './App.css';

function rot(v: number) {
  var r = 0
  for (var i = 0; i < 8; i++) {
    let x = v & 1
    r = (r << 1) | x
    v = v >> 1
  }
  return r
}

function code(v: number): number {
  let x = rot(v)
  return 0xe0e00000 | (x << 8) | (0xff ^ x)
}

function App1() {
  var codes: number[] = []
  for (var i = 0; i < 256; i++) {
    let x = rot(i)
    codes[i] = (x << 8) | (0xff ^ x)
  }
  return <div className="App" style={{ margin: '30px', display: 'flex', flexWrap: 'wrap' }}>
    {codes.map((i, j) =>
      <div>
        <button style={{ margin: 20, padding: 20, fontSize: '1rem', width: 300 }} onClick={() => {
          send(0xE0E00000 | i)
        }}>{j} - 0x{j.toString(16)} - 0xE0E0{i.toString(16).padStart(4, "0")}</button>
      </div>)}
  </div>
}

interface ButtonProps {
  label: string
  code: number
}

function Button(p: ButtonProps) {
  return <button style={{ margin: 5, padding: 5, fontSize: '1rem', minWidth: 90 }} onClick={() => {
    send(code(p.code))
  }}>{p.label}</button>
}

function Row(p: any) {
  return <div {...p} style={{ margin: '5px', display: 'flex', flexWrap: 'wrap', justifyContent: "space-between", alignItems: "center" }} ></div>
}

function App() {
  return (
    <div className="App noselect" style={{ marginTop: '40px' }}>
      <Row>
        <Button label="on" code={0x99}></Button>
        <Button label="off" code={0x98}></Button>
        <Button label="on/off" code={0x2}></Button>
      </Row>
      <div style={{ marginTop: 15 }}></div>
      <Row>
        <Button label="TV" code={0x1b}></Button>
        <Button label="SOURCE" code={0x1}></Button>
        <Button label="HDMI 1" code={233}></Button>
      </Row>
      <div style={{ marginTop: 15 }}></div>
      <Row>
        <div>
          <div><Button label="+" code={0x7}></Button></div>
          <div>vol</div>
          <div><Button label="-" code={0xb}></Button></div>
        </div>
        <div>
          <div><Button label="mute" code={0xf}></Button></div>
          <div><Button label="prev" code={0x13}></Button></div>
        </div>
        <div>
          <div><Button label="˄" code={0x12}></Button></div>
          <div>ch</div>
          <div><Button label="˅" code={0x10}></Button></div>
        </div>
      </Row>
      <div style={{ marginTop: 15 }}></div>
      <Row>
        <div></div>
        <Button label="↑" code={0x60}></Button>
        <div></div>
      </Row>
      <Row>
        <Button label="←" code={0x65}></Button>
        <Button label="↲" code={0x68}></Button>
        <Button label="→" code={0x62}></Button>
      </Row>
      <Row>
        <div></div>
        <Button label="↓" code={0x61}></Button>
        <div></div>
      </Row>
      <Row>
        <Button label="Ret" code={0x58}></Button>
        <Button label="Smart" code={140}></Button>
        <Button label="Exit" code={0x2d}></Button>
      </Row>
      <div style={{ marginTop: 15 }}></div>
      <Row>
        <Button label="1" code={0x4}></Button>
        <Button label="2" code={0x5}></Button>
        <Button label="3" code={0x6}></Button>
      </Row>
      <Row>
        <Button label="4" code={0x8}></Button>
        <Button label="5" code={0x9}></Button>
        <Button label="6" code={0xa}></Button>
      </Row>
      <Row>
        <Button label="7" code={0xc}></Button>
        <Button label="8" code={0xd}></Button>
        <Button label="9" code={0xe}></Button>
      </Row>
      <Row>
        <div></div>
        <Button label="0" code={0x11}></Button>
        <div></div>
      </Row>
      
    </div>
  );
}

async function send(code: number) {
  try{
  let codeStr = BigInt.asIntN(32, BigInt(code)).toString()
  const response = await fetch(process.env.REACT_APP_API_URI ?? "", {
    method: 'POST',
    mode: 'cors',
    cache: 'no-cache',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ command: 'dir', code: codeStr })
  })
  const responseBody = await response.text()
  console.log(response.status + " " + responseBody)
  }catch (e){
    console.error(e)
  }
}

export default App;
